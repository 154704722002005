@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import "swiper/css";
@import "swiper/css/navigation";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~@flaticon/flaticon-uicons/css/all/all";

@layer base {
  button:disabled {
    display: initial !important;
    opacity: 0.5;
    cursor: not-allowed;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
body {
  background: #e8f0ff;
  scroll-behavior: smooth;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::-moz-selection {
  /* Code for Firefox */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

::selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*Latest fonts added by AAQIB */
@font-face {
  font-family: "Inter";
  src: url("../src/assets/fonts/Inter-VariableFont_opsz,wght.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "PT Sans";
  src: url("../src/assets/fonts/PTSans-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "IBM Plex Sans Condensed";
  src: url("../src/assets/fonts/IBMPlexSansCondensed-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* .max-w-screen-lg {
  max-width: 1200px;
} */

.main-banner {
  /* background-image: url("../src/assets/img/main-banner-trades-poster.jpg"); */
  /* object-fit: center !important; */
  background-image: url("../src/assets/img/latest-main-banner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  /* filter : brightness(120%) */
}
.secondary-banner {
  /* background-image: url("../src/assets/img/main-banner-trades-poster.jpg"); */
  /* object-fit: center !important; */
  background-image: url("../src/assets/img/latest-main-banner.jpg");
  background-position: center 100%;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
}
.trades-poster-spaciality-banner {
  /* background-image: url("../src/assets/img/main-banner-trades-poster.jpg"); */
  /* object-fit: center !important; */
  background-image: url("../src/assets/img/TradesPosterSpeciality/trades-poster-speciality-banner.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  height: 100vh;
}
.p-autocomplete-panel {
  padding: 12px !important;
  margin: 15px -12px;
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.custom-dropdown-signup .p-dropdown-trigger{
  display: none !important;
}
.p-component {
  background-color: #ffffff !important;
}
.p-inputtext {
  width: 100% !important;
  background-color: transparent !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
  border: none !important;
}
.p-inputtext::placeholder {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
  color: #bdbebf !important;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input::placeholder {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
  color: #bdbebf !important;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  text-transform: none !important;
  color: #bdbebf !important;
}

input:focus,
textarea:focus {
  outline: none;
}

/* Range Slider */

#range2 {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 6px;
  background: rgb(238 238 238);
}

#range2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 20px;
  width: 20px;
  background-color: #2e00ff;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

#range2::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background-color: #2e00ff;
  border-radius: 50%;
  border: none;
  transition: 0.2s ease-in-out;
}

.swiper-pagination-bullet-active {
  background-color: #2e00ff !important;
}

/* Scrolllbar css */
.hidden-scrollbar {
  /**
    FIREFOX
  */
  scrollbar-width: none;

  /**
    CHROME, EDGE, OPERA and SAFARI
  */
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.div1:hover + .div2 {
  opacity: 0.2;
}

.div2:hover {
  opacity: 0.2;
}
input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
  }
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px rgb(238 238 238 / var(--tw-bg-opacity))
    inset;
  -moz-box-shadow: 0 0 0 100px rgb(238 238 238 / var(--tw-bg-opacity)) inset;
  box-shadow: 0 0 0 100px rgb(238 238 238 / var(--tw-bg-opacity)) inset;
}

.card-shadow {
  -moz-box-shadow: 0 0 30px #ccc;
  -webkit-box-shadow: 0 0 30px #ccc;
  box-shadow: 0 0 30px #ccc;
}

.card-shadow-dashboard {
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}
.normal-shadow {
  -moz-box-shadow: 0 0 6px #ccc;
  -webkit-box-shadow: 0 0 6px #ccc;
  box-shadow: 0 0 6px #ccc;
}

.p-component:focus {
  border: 1px solid #2200bf !important;
  border-radius: 8px !important;
}

input:-webkit-autofill {
  box-shadow: none !important;
}

.p-rating .p-rating-item .p-rating-icon {
  font-size: 12px !important;
}

.pi {
  font-size: 14px !important;
  /* color : #232A2E !important; */
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.sliderImg:last-of-type {
  padding-left: 0;
}

.sliderWrapper {
  display: flex;
}

.sliderSection {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}
.sliderSectionReverse {
  display: flex;
  animation: swiperevers var(--speed) linear infinite backwards;
}

.stopAnimation {
  animation-play-state: paused;
}

@keyframes swipe {
  0% {
    transform: translate(0%);
  }
  100% {
    transform: translate(-100%);
  }
}
@keyframes swiperevers {
  0% {
    transform: translate(-100%);
  }
  100% {
    transform: translate(0%);
  }
}

.safari {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.safari::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.p-rating .p-rating-item.p-rating-item-active .p-rating-icon {
  color: #2e00ff !important;
}
.p-rating .p-rating-item .p-rating-icon {
  font-size: 12px !important;
}

/* portfolio images corner radius */
.react-photo-album--column img {
  /* min-width: 400px !important; */
  border-radius: 24px !important;
}

/* primereact components */

.p-checkbox .p-checkbox-box {
  display: none !important;
}
.p-multiselect-token-label {
  font-size: 12px !important;
  font-family: "Inter" !important;
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 8px 8px 8px 8px !important;
  color: #ffffff !important;
  background-color: #2200bf !important;
  border-radius: 18px !important;
}
.p-autocomplete-panel {
  width: 420px !important;
}

.p-chips .p-chips-multiple-container .p-chips-token {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  background-color: #2200bf !important;
  border-radius: 18px !important;
  margin-bottom: 8px !important;
}
.p-multiselect-panel {
  padding: 12px !important;
  margin-top: 16px !important;
  margin-bottom: 16px !important;
  border-radius: 8px !important;
}

.p-dropdown-item:hover {
  background-color: transparent !important;
  color: #2e00ff !important;
}
.custom-multiselect-panel {
  background: #FFFFFF !important;
  /* Add other customizations as needed */
}
.custom-multiselect-panel .p-multiselect-item {
  background: #FFFFFF !important;
}
.custom-multiselect-panel .p-multiselect-item:hover {
  background: #FFFFFF !important;
}
.custom-multiselect-panel .p-multiselect-items .p-component{
  background: #FFFFFF !important;
}
.p-dropdown {
  padding: 12px !important;
  background-color: #ffffff !important;
  border: none !important;
}
.p-placeholder {
  color: #bdbebf !important;
}
.p-multiselect-panel .p-multiselect-items {
  padding : 0px 0px 0px 0px !important;
}
.p-multiselect-panel{
  box-shadow: none !important;
}
.p-dropdown-panel {
  padding: 12px !important;
  margin-top: 16px !important;
  border-radius: 8px !important;
  background-color: #ffffff !important;
}

.p-multiselect-close {
  display: none !important;
}
.p-multiselect-header {
  background-color: transparent !important;
}
.p-dropdown-header {
  background-color: transparent !important;
}
.p-multiselect-item {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  background: transparent !important;
}
.p-dropdown-item {
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
  background: transparent !important;
}

/* fully customized */

.p-dropdown.custom-dropdown-field {
  padding: 12px !important;
  background-color: #FFFFFF !important;
  border: none !important;
}
.p-dropdown.custom-dropdown-field .p-inputtext {
  padding: 0px !important;
}
.p-dropdown-trigger-custom .p-dropdown-trigger {
  width: 16px !important;
}

.custom-multiselect-bg.p-multiselect.p-component.p-inputwrapper.p-multiselect-chip {
  padding: 12px !important;
  background-color: #FFFFFF !important;
  border: none !important;
}

.p-chips.p-component.p-inputwrapper.custom-chips-bg {
  /* padding: 12px !important; */
  background-color: #FFFFFF !important;
  border: none !important;
}
.custom-dropdown-panel {
  background-color: #FFFFFF !important;
  box-shadow: none !important;
  padding: 8px !important;
}
.custom-dropdown-panel .p-dropdown-item {
  color: #232a2e !important;
  font-family: "Inter" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 1.4 !important;
  letter-spacing: 0px !important;
}
.custom-dropdown-panel .p-dropdown-item:hover {
  color: #2e00ff !important;
}

.p-component {
  border-radius: 8px !important;
  border: none !important;
}
.p-component:hover {
  box-shadow: none !important;
  border: none !important;
}
.p-multiselect-item {
  box-shadow: none !important;
  border: none !important;
}
.p-multiselect-item:hover {
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
}
.p-multiselect-item:hover {
  color: #2e00ff !important;
}
.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: 1px solid #2e00ff !important;
}
.p-multiselect-items-wrapper {
  /**
    FIREFOX
  */
  scrollbar-width: none;

  /**
    CHROME, EDGE, OPERA and SAFARI
  */
  &::-webkit-scrollbar {
    width: 0px;
  }
}

.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: 1px solid #2e00ff !important;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  box-shadow: none !important;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none !important;
}
.p-dropdown .p-dropdown-trigger {
  color: #232a2e !important;
}
.custom-dropdown-paceholder .p-placeholder {
  color: #232a2e !important;
}


/* Customise the angle down */

.custom-multiselect {
  position: relative;  
}
.custom-multiselect .p-multiselect-trigger-icon {
  display: none; 
}
.custom-multiselect .custom-multiselect-icon {
  position: absolute;
  top: 50%; /* Adjust as needed */
  right: 12px; /* Adjust as needed */
  transform: translateY(-50%);
  pointer-events: none;  
}
.custom-dropdown {
  position: relative;  
}
.custom-dropdown .p-dropdown-trigger-icon {
  display: none; /* Hide the default down arrow icon */
}
.custom-dropdown .custom-dropdown-icon {
  position: absolute;
  top: 50%; /* Adjust as needed */
  right: 12px; /* Adjust as needed */
  transform: translateY(-50%);
  pointer-events: none;  
}



/* Upside and downside movements */

.sliderUpside {
  /* display: flex; */
  animation: swipe-up var(--speed) linear infinite backwards;
}

@keyframes swipe-up {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.sliderDownside {
  /* display: flex; */
  animation: swipe-down var(--speed) linear infinite backwards;
}

@keyframes swipe-down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.fadeOut {
  opacity: 0;
  transition: opacity 10s ease-out;
}
.fadeIn {
  opacity: 1;
  transition: opacity 20s ease-in;
}

/* Pro page zoomed */

.hero__cover {
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.hero__inner {
  display: flex;
  position: relative;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.hero__cover > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  transform: translate(-50%, -50%);
  z-index: 100;
}

.hero__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}


.swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background-color: #FFFFFF !important;
  border-radius: 50%;
  margin: 0 12px !important;
  cursor: pointer;
  transition: width 0.3s ease, background-color 3s ease; /* Add transition */
}

.swiper-pagination-bullet-active {
  background-color: #FFFFFF !important; /* Change this to your desired active color */
  width: 50px;
  height: 9px;
  border-radius: 35px;
  transition: width 0.3s ease, background-color 3s ease; /* Ensure active bullet also has transition */
}

.swiper-pagination-container {
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 40rem) {
  .p-inputtext {
    width: 100% !important;
    background-color: transparent !important;
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    border: none !important;
  }
  .p-inputtext::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 11px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
}
@media screen and (min-width: 48rem) {
  .p-inputtext {
    width: 100% !important;
    background-color: transparent !important;
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    border: none !important;
  }
  .p-inputtext::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
}
@media screen and (min-width: 64rem) {
  .custom-multiselect-panel {
    background: #E8F0FF !important;
    /* Add other customizations as needed */
  }
  .custom-multiselect-panel .p-multiselect-item {
    background: #E8F0FF !important;
  }
  .custom-multiselect-panel .p-multiselect-item:hover {
    background: #E8F0FF !important;
  }
  .custom-multiselect-panel .p-multiselect-items .p-component{
    background: #E8F0FF !important;
  }
  .custom-multiselect-bg.p-multiselect.p-component.p-inputwrapper.p-multiselect-chip {
    padding: 12px !important;
    background-color: #e8f0ff !important;
    border: none !important;
  }
  .p-dropdown.custom-dropdown-field {
    padding: 12px !important;
    background-color: #e8f0ff !important;
    border: none !important;
  }
  .custom-dropdown-panel {
    background-color: #e8f0ff !important;
    box-shadow: none !important;
    padding: 8px !important;
  }
  .p-chips.p-component.p-inputwrapper.custom-chips-bg {
    padding: 12px !important;
    background-color: #e8f0ff !important;
    border: none !important;
  }
}
@media screen and (min-width: 80rem) {
  .p-inputtext {
    width: 100% !important;
    background-color: transparent !important;
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    border: none !important;
  }
  .p-inputtext::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-chips .p-chips-multiple-container .p-chips-token {
    /* height: 22px !important; */
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    color: #ffffff !important;
    background-color: #2200bf !important;
    border-radius: 18px !important;
    margin-bottom: 8px !important;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .custom-dropdown-panel {
    background-color: #e8f0ff !important;
    box-shadow: none !important;
    padding: 8px !important;
  }
  .main-banner {
    /* background-image: url("../src/assets/img/main-banner-trades-poster.jpg"); */
    /* object-fit: center !important; */
    background-image: url("../src/assets/img/latest-main-banner.jpg");
    background-position: center 100%;
    background-repeat: no-repeat;
    height: 100vh;
    /* background-size: cover; */
    position: relative;
    /* -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover; */
    /* filter : brightness(120%) */
  }
  .p-autocomplete-panel {
    margin: 16px -12px;
    background: #e8f0ff;
    color: #495057;
    border: 0 none;
    border-radius: 8px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }

  /* .p-component {
    background-color: #ffffff !important;
  } */

  .p-dropdown-items-wrapper {
    /**
      FIREFOX
    */
    scrollbar-width: none;

    /**
      CHROME, EDGE, OPERA and SAFARI
    */
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
}
@media screen and (min-width: 96rem) {
  .p-inputtext {
    width: 100% !important;
    background-color: transparent !important;
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    border: none !important;
  }
  .p-inputtext::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-chips .p-chips-multiple-container .p-chips-input-token input::placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .p-multiselect .p-multiselect-label.p-placeholder {
    font-family: "Inter" !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    line-height: 1.4 !important;
    letter-spacing: 0px !important;
    text-transform: none !important;
    color: #bdbebf !important;
  }
  .custom-dropdown-panel {
    background-color: #e8f0ff !important;
    box-shadow: none !important;
    padding: 8px !important;
  }
}
